import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout/layout.js';
import styles from '../styles/contact.module.css';

const Contact = () => (
  <Layout>
    <Helmet>
      <title>River City Drafthouse</title>
      <meta
        name='description'
        content='River City Drafthouse is a family-owned pub serving Asian and American dishes'
      />
    </Helmet>

    <section className={styles.contactContainer}>
      <div className={styles.about}>
        <section className={styles.aboutInfo}>
          <h1 className={styles.contactHeader}>
            About & Contact
          </h1>
          <p className={styles.aboutParagraph}>
          Happy Hour is 4:00-6:30 PM, daily, with Sunday all day Happy Hour: 1$ off select drafts and select appetizers
              </p>
          <p className={styles.aboutParagraph}>
          River City Drafthouse is a family owned business specializing in American & Asian dishes, craft beer with over 20 local and national offerings on tap and many more in bottles. Amazing wings and fresh Sushi also await you, so there is sure to be something for everyone.
          </p>
        </section>
        <aside className={styles.contact}>
          <div className={styles.contactSection}>
            <h1 className={styles.contactHeader}>Phone</h1>
            <p className={styles.contactParagraph}>502-690-5111</p>
          </div>
          <div className={styles.contactSection}>
            <h1 className={styles.contactHeader}>Location</h1>
            <p className={styles.contactParagraph}>1574 Bardstown Rd</p>
            <p className={styles.contactParagraph}>Louisville, KY 40205</p>
          </div>
          <div className={styles.contactSection}>
            <h1 className={styles.contactHeader}>Hours</h1>
            <p className={styles.contactParagraph}>
              <b>Sun</b> 12:00 PM - 9:00 PM
            </p>
            <p className={styles.contactParagraph}>
              <b>Mon</b> Closed
            </p>
            <p className={styles.contactParagraph}>
              <b>Tuesday-Friday</b> 4:00 PM - 10:00 PM 
            </p>
            <p className={styles.contactParagraph}>
              <b>Saturday</b> 12:00 PM - 10:00 PM
            </p>
          </div>
        </aside>
      </div>
      <section className={styles.contactMap}>
        <iframe
          src='https://www.google.com/maps/place/River+City+Drafthouse/@38.2308877,-85.7143093,15z/data=!4m5!3m4!1s0x88690cc9d09301a1:0x35ebd8a5c3f09a32!8m2!3d38.2308866!4d-85.7055546'
          width='2000'
          height='4000'
          frameBorder='0'
          style={{ width: '100%', height: '100%', border: 0 }}
          allowFullScreen
        />
      </section>
    </section>
  </Layout>
);

export default Contact;
